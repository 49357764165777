.img-sm {
  height: 1em;
}

.scale-active {
  transition: transform 250ms;
  &:active {
    transform: scale(0.90);
  }
}

.shadow-hover {
  &:hover {
    @extend .shadow;
  }
}
