quotely-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
}

router-outlet + * {
  flex: 1 1 auto !important;
}
