quotely-quote-card > .quote-card {
  max-width: 40em;
  min-height: 12em;
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 1fr auto;
  cursor: pointer;
  visibility: hidden;

  .menu{
    position: absolute;
    top: 0.25em;
    right: 0.5em;
  }

  .card-footer {
    grid-column: 1 / span 2;
  }

  .context {
    transition: line-height 150ms, opacity 250ms ease-out;
    line-height: 0em;
    opacity: 0;
    overflow: hidden;
  }

  .card-body, .voting {
    visibility: visible;
  }

  &:hover {
    visibility: visible;
    .context {
      line-height: 1.5em;
      opacity: 1;
    }
  }

  .fav-btn:hover {
    color: $danger !important;
  }
}
