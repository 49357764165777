quotely-header {
  .header {
    min-height: 400px;
    height: 400px;
  }

  .wave-divider {
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;

    svg {
      position: relative;
      display: block;
      width: calc(118% + 1.3px);
      height: 73px;
    }

    .shape-fill {
      fill: #F1948A;
    }
  }
}
