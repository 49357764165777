.btn {
  transition: transform 250ms, box-shadow 150ms;
  @extend .scale-active;
  @extend .shadow-hover;
}

.btn-icon {
  @extend .p-1;
  border-radius: 50em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.3em;
  height: 2.3em;

  &:hover {
    background-color: $gray-200;
  }

  @extend .scale-active;
}
